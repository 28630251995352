import api from '../httpServer'
import config from '../config'

const meeting = {
    //会议管理分页查询
    getMeetingInfoPageList(data) {
        return api.get(`${config.headerUrl}meeting/getMeetingInfoPageList`, data)
    },
    //会场管理分页查询
    getMeetingPlacePageList(data) {
        return api.get(`${config.headerUrl}meeting/getMeetingPlacePageList`, data)
    },
    //所属酒店查询下拉框列表
    getHotelList(data) {
        return api.get(`${config.headerUrl}room/getHotelList`, data)
    },
    // 会场下拉列表
    getMeetPlaceByHotelId(data) {
        return api.get(`${config.headerUrl}meeting/getMeetPlaceByHotelId`, data)
    },
    // 查看附件
    getMeetPlaceAnnex(data) {
        return api.get(`${config.headerUrl}meeting/getHotelAnnex/${data.placeId}`, data)
    },
    //会场管理添加
    AddMeetingPlaceInfo(data) {
        return api.post(`${config.headerUrl}meeting/AddMeetingPlaceInfo`, data)
    },
    //会场管理删除
    deleteMeetingPlaceInfo(data) {
        return api.del(`${config.headerUrl}meeting/deleteMeetingPlaceInfo/${data}`)
    },

    /*
    * 会议管理
    * */
    //会议管理新增
    AddMeetingInfo(data) {
        return api.post(`${config.headerUrl}meeting/AddMeetingInfo`, data)
    },
    //会议管理详情
    meetingInfoDetial(data) {
        return api.get(`${config.headerUrl}meeting/meetingInfoDetial`, data)
    },
    //会议名称下拉列表
    getMeetInfoByPlaceId(data) {
        return api.get(`${config.headerUrl}meeting/getMeetInfoByPlaceId`, data)
    },
    //会议管理编辑
    updateMeetingUserInfo(data) {
        return api.post(`${config.headerUrl}meeting/updateMeetingUserInfo`, data)
    },
    //会议管理删除
    deleteMeetingInfo(data) {
        return api.del(`${config.headerUrl}meeting/deleteMeetingInfo/${data}`)
    },
    /*
    *  参会人员
    * */
    // 添加
    addMeetPerson(data) {
        return api.post(`${config.headerUrl}meetPerson/addMeetPerson`, data)
    },
    // 可参会人员列表
    getMayMeetPersonList(data) {
        return api.get(`${config.headerUrl}meetPerson/getMayMeetPersonList`, data)
    },
    // 参会人员列表
    getMeetPersonList(data) {
        return api.get(`${config.headerUrl}meetPerson/getMeetPersonList`, data)
    },
    /*
    * 会议管理-会议纪要
    * */
    // 添加
    addMeetingMinutesInfo(data) {
        return api.post(`${config.headerUrl}meet/addMeetingMinutesInfo`, data)
    },
    // 删除
    deleteMeetMinutes(data) {
        return api.del(`${config.headerUrl}meet/deleteMeetMinutes/${data.minuId}`, data)
    },
    //查看附件
    getHotelAnnex(data) {
        return api.get(`${config.headerUrl}meet/getHotelAnnex/${data.minuId}`, data)
    },
    //分页查询
    getMeetMinutesPageList(data) {
        return api.get(`${config.headerUrl}meet/getMeetMinutesPageList`, data)
    },
    //详情
    getMeetingMinutesDetial(data) {
        return api.get(`${config.headerUrl}meet/getmeetingMinutesDetial`, data)
    },
    //编辑
    updateMeetingMinutes(data) {
        return api.get(`${config.headerUrl}meet/updateMeetingMinutes`, data)
    },
    /*
    * 会议管理-活动锦集
    * */
    // 添加
    addMeetActivity(data) {
        return api.post(`${config.headerUrl}activity/addMeetActivity`, data)
    },
    //分页查询
    getMeetActivetyPageList(data) {
        return api.get(`${config.headerUrl}activity/getMeetActivetyPageList`, data)
    },
    // 查看附件
    getActivetyAnnexList(data) {
        return api.get(`${config.headerUrl}activity/getHotelAnnex/${data.activId}`, data)
    },
    // 附件下载
    downloadActivetyAnnex(data) {
        return api.down(`${config.headerUrl}activity/download1`, data)
    },

}


export default meeting